<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background: repeating-linear-gradient(
		      140deg,
		      #F7A37B,
		      #F7A37B 1em,
		      #FFDEA8 1em,
		      #FFDEA8 2em,
		      #D0E4B0 2em,
		      #D0E4B0 3em,
		      #7CC5D0 3em,
		      #7CC5D0 4em,
		      #00A2E1 4em,
		      #00A2E1 5em,
		      #0085C8 5em,
		      #0085C8 6em
		    );
			mix-blend-mode: normal;
      		margin-top: 0px;
}
</style>
