// 此文件专门负责项目的路由

import VueRouter from "vue-router"

// 引入组件
import Home from '../views/home/Home'
import { Message } from "element-ui";

// 创建并暴露一个路由器
const router = new VueRouter({
    mode: 'hash',    // 路由模式，该模式不会在地址中显示井号# mode: 'history',  
    routes: [
        {
            path: '*',     // 路径
            redirect: '/word'   // 跳转到的组件
        },
        {
            path: '/word',     // 路径
            component: Home    // 跳转到的组件
        },
    ]
})
// 导航守卫，前置处理
router.beforeEach((to, from, next) => {
    // 如果路由要跳转到除了登录和注册的界面的话就判断是否已经登录，如果没有登录就强制跳到登录界面
    // if (to.path != '/word' ) {
    //     next({ path: '/word' })
    //     Message({
    //         message: '查询成功！',
    //         type: "success",
    //     });
    // } 
    // else 
    next()
})

export default router;