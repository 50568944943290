<template>
	<!-- 页头开始 -->
	 <div class="page">
	<div class="page1">
		<!-- {{title[0].english_number}} -->
		<span class="logo-name" style="margin-left:2rem;">{{title[0].english_number}}</span>
		<!-- {{ title[0].number}} -->
		<span class="logo-name-1" style="margin-left:-2.5rem;margin-top:3rem;">{{ title[0].number}}</span>
		<el-image class="logo" :src=title1[0].image ></el-image>
		<el-image class="logo1" :src=title1[1].image ></el-image>
	</div>
	<!-- 页头结束 -->
	<!-- 页头中间开始 -->
	<div class="page2">
		<span class="logo-name-2">Unit {{ title[0].unit_number}}</span>
	</div>
	<!-- 页头中间结束 -->
	<!-- 中间开始 -->
	 <!-- 开始 -->
		<div class="content" v-if="$data.title.length == 1">
			<div style="margin-top:2rem;margin-bottom:2rem;margin-left:auto;margin-right:auto;">
			<span class="title" style="margin-top:2rem;margin-left:auto;margin-right:auto;">{{title[0].letter}}</span>
			</div>
			<div style="margin-top:-2rem">
			<el-image :src=title[0].icon_image style="max-width: 100%;max-height:100%;width: 20rem;margin-top: 4rem;"></el-image>
		 </div>
			<div class="span-area">
				<audio :src=title[0].icon_audio style="margin-left:1.5rem;margin-right: 1.5rem;" controls></audio>
				
			</div>
			
			</div>
			<!-- 开始 -->
		<div class="content" v-if="$data.title.length == 2">
			<div>
				
				<div style="margin-top:1.5rem;margin-bottom:2rem;margin-left:auto;margin-right:auto;">
				<span class="title" >{{title[0].letter}}</span>
			</div>
			<div  style="margin-top:-1.5rem;">
			<el-image :src=title[0].icon_image style="max-width: 100%;max-height:100%;width: 8rem;margin-top: 3rem;"></el-image>
			</div>
			<div class="span-area">
				<audio :src=title[0].icon_audio style="margin-left:1.5rem;margin-right: 1.5rem;" controls></audio>
			</div>
			
			</div>
			<div>
				<div style="margin-top:1.5rem;margin-bottom:2rem;margin-left:auto;margin-right:auto;">
				<span class="title">{{title[1].letter}}</span>
			   </div>
				<div style="margin-top:-1.5rem;">
			<el-image :src=title[1].icon_image style="max-width: 100%;max-height:100%;width: 8rem;margin-top: 1rem;"></el-image>
		    </div>
			<div class="span-area">
				<audio :src=title[1].icon_audio style="margin-left:1.5rem;margin-right: 1.5rem;" controls></audio>
			</div>
			
			</div>
		</div>
		<!-- 开始 -->
		<div class="content" v-else-if="$data.title.length >= 3" style="display: block;" >
			<div v-for="item in title" :key="index">
			<div style="margin-top:2rem;margin-bottom:2rem;margin-left:auto;margin-right:auto;">
					<span class="title">{{ item.letter }}</span>
			</div>
			<div style="margin-top:-1.5rem">
			<el-image :src=item.icon_image style="max-width: 100%;max-height:100%;width: 5rem;margin-top: 3rem;"></el-image>
			<div class="span-area">
				<audio :src=item.icon_audio style="margin-left:1.5rem;margin-right: 1.5rem;" controls></audio>
			</div>
			
			</div>
		   </div>
		</div>
	<!-- 中间结束 -->
	<!-- 页末开始 -->
	<div class="footer">
		<span>Copyright&copy;GUGA Education Inc</span>
	</div>
</div>
	<!-- 页末结束 -->
</template>

<script>
	export default {
		data() {
			return {
				title:[],
				title1:[],
        		gugaList1_1:[],
				form1:{
					id:'',
					organizationId:'',
				},
				letter_1:'',
				list1:[],
				list2:[],
				list3:[],
				form2:{
					id:'',
					organizationId:'',
					type:'',
				},
			}
		},
		
		methods: {
			getList111(){
				this.axios({
				url:"/api/word",
				method:"post",
				headers: {                            // 请求头
						"Content-Type": "application/json,charset=utf-8",
						},
						params:this.form1
				}).then((res)=>{
				// 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
				if (res.data.code === "0") {
					this.gugaList1_1 = res.data.data  
						let num1 =  res.data.data.numbers
						console.log("111",num1)
						const jsonString = num1;
            			const obj = JSON.parse(jsonString);
            			const ids = obj.id.split(',');
						var nums1 = [];
						//let Lists = [];
						for (let i = 0; i < ids.length; i++) {
						  nums1[i] = ids[i]
						}
						this.list1 = nums1
						this.getList222();

						console.log("12121--->",this.title)

						let num2 =  res.data.data.logo
						console.log("num2",num2)
						const jsonString1 = num2;
            			const obj1 = JSON.parse(jsonString1);
            			const ids1 = obj1.id.split(',');
						var nums2 = [];
						//let Lists = [];
						for (let q = 0; q < ids1.length; q++) {
						  nums2[q] = ids1[q]
						}
						this.list2 = nums2
						this.getList555();
						console.log("list2--->",this.list2)
						// }
						// console.log("1--->",this.list1);
						
						} 
					});
			},
			getList222(){
				const inputIds = this.list1
				const idMap= {};
				const listItem = [];
				this.axios({
				url:"/api/admin/findByLetter1?ids="+this.list1,//url:"/api/admin/findByLetter?letter="+this.letter_1,
				method:"post",
				headers: {                            // 请求头
						"Content-Type": "application/json,charset=utf-8",
						},
				}).then((res)=>{
					const base64ToUrl3 = (base64)=>{
						return "data:image/png;base64," + base64;
					};
					const base64ToUrl4 = (base64)=>{
						return "data:audio/mp3;base64," + base64;
					};
					console.log(res.data[0],"-->")
					if(res.data != null){
						for(let k = 0; k < res.data.length;k++){
						const item = res.data[k];
						item.icon_image = atob(item.icon_image)
						item.icon_image = base64ToUrl3(item.icon_image)
						item.icon_audio = atob(item.icon_audio)
						item.icon_audio = base64ToUrl4(item.icon_audio)
						//拼接数组
						idMap[item.id] = item;
					}  
					// 根据输入的 ID 顺序重新排序数据
					for (const id of inputIds) {
							if (id in idMap) {
								listItem.push(idMap[id]);
							}
							}
					}
						this.title = listItem
						console.log("3---->",this.title)
					});
			},
			getList555(){
				const inputIds1 = this.list2
				const idMap1= {};
				const listItem1 = [];
				this.axios({
				url:"/api/admin/findByName1?ids=" + this.list2,
				method:"post",
				headers: {                            // 请求头
						"Content-Type": "application/json,charset=utf-8",
						},
				}).then((res)=>{
					const base64ToUrl4 = (base64)=>{
						return "data:image/png;base64," + base64;
					};
					console.log(res.data[0],"-->1")
					if(res.data != null){
						for(let t = 0; t < res.data.length;t++){
							const item1 = res.data[t];
							item1.image = atob(item1.image)
							item1.image = base64ToUrl4(item1.image)
							idMap1[item1.id] = item1;
					}  
					// 根据输入的 ID 顺序重新排序数据
					for (const id of inputIds1) {
							if (id in idMap1) {
								listItem1.push(idMap1[id]);
							}
							}
					}
						this.title1 = listItem1
						console.log("31---->",this.title1)
					});
			},

			getList333(){
				this.axios({
				url:"/api/word",
				method:"get",
				headers: {                            // 请求头
						"Content-Type": "application/json,charset=utf-8",
						},
						params:this.form2
				}).then((res)=>{
				// 当收到后端的响应时执行该括号内的代码，res 为响应信息，也就是后端返回的信息
				if (res.data.code === "0") {
					this.gugaList1_1 = res.data.data  
						let num1 =  res.data.data.numbers
						console.log("111",num1)
						const jsonString = num1;
            			const obj = JSON.parse(jsonString);
            			const ids = obj.id.split(',');
						var nums1 = [];
						//let Lists = [];
						for (let i = 0; i < ids.length; i++) {
						  nums1[i] = ids[i]
						}
						this.list1 = nums1
						console.log("list1--->",this.list1)
						this.getList444();
						console.log("12121--->",this.title)

						let num4 =  res.data.data.logo
						console.log("num4",num4)
						const jsonString4 = num4;
            			const obj4 = JSON.parse(jsonString4);
            			const ids4 = obj4.id.split(',');
						var nums4 = [];
						//let Lists = [];
						for (let y = 0; y < ids4.length; y++) {
						  nums4[y] = ids4[y]
						}
						this.list3 = nums4
						this.getList666();
						console.log("list3--->",this.list3)
						

						// }
						// console.log("1--->",this.list1); 
						} 
					});
			},
			getList444(){
				        const inputIds2 = this.list1
						const idMap2= {};
						const listItem2 = [];
						const base64ToUrl3 = (base64) => {
						return "data:image/png;base64," + base64;
						};
						const base64ToUrl4 = (base64) => {
						return "data:audio/mp3;base64," + base64;
						};

						this.axios({
						url: "/api/admin/findByLetter2?ids=" + this.list1,
						method: "get",
						headers: {
							"Content-Type": "application/json,charset=utf-8",
						},
						}).then((res) => {
						if (res.data != null) {
							for (let m = 0; m < res.data.length; m++) {
							const item2 = res.data[m];
							item2.icon_image = atob(item2.icon_image);
							item2.icon_image = base64ToUrl3(item2.icon_image);
							item2.icon_audio = atob(item2.icon_audio);
							item2.icon_audio = base64ToUrl4(item2.icon_audio);

							idMap2[item2.id] = item2;
							}

							// 根据输入的 ID 顺序重新排序数据
							for (const id of inputIds2) {
							if (id in idMap2) {
								listItem2.push(idMap2[id]);
							}
							}
						}

						this.title = listItem2;
						console.log("3---->", this.title);
						});
			},

			
			getList666(){
				const inputIds3 = this.list3
				const idMap3 = {};
				const listItem3 = []
				this.axios({
				url:"/api/admin/findByName2?ids=" + this.list3,
				method:"get",
				headers: {                            // 请求头
						"Content-Type": "application/json,charset=utf-8",
						},
				}).then((res)=>{
					const base64ToUrl6 = (base64)=>{
						return "data:image/png;base64," + base64;
					};
					console.log(res.data[0],"-->1")
					if(res.data != null){
						for(let v = 0; v < res.data.length;v++){
							const item3 = res.data[v];
							item3.image = atob(item3.image)
							item3.image = base64ToUrl6(item3.image)
							idMap3[item3.id] = item3;
					}  
					// 根据输入的 ID 顺序重新排序数据
					for (const id of inputIds3) {
							if (id in idMap3) {
								listItem3.push(idMap3[id]);
							}
							}
					}
						this.title1 = listItem3						
						console.log("32---->",this.title1)
					});
			}
		},
		mounted() {
			// this.$nextTick(() => { 
			// 	this.getList(id,organizationId,type);// 异步操作
			// 	 }) 
			// 获取URL参数
			let id1 = this.$route.query.id
			this.form1.id = id1
			let organizationId1 = this.$route.query.organizationId
			this.form1.organizationId = organizationId1
			this.getList111();

			let id2 = this.$route.query.id
			this.form2.id = id2
			let organizationId2 = this.$route.query.organizationId
			this.form2.organizationId = organizationId2
			let type2 = this.$route.query.type
			this.form2.type = type2
			this.getList333();
		},
		
	}
	 
</script>

<style>
	.page{
		width: 100%;
		padding:0px;
	}
	.page1{
		display: flex;
		background-color: white;
		border-radius: 1rem;
		width: 90%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}
	.page2{
		background-color: white;
		border-radius: 1rem;
		width: 20rem;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
	.logo-name{
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		font-weight: 600 !important;
		color: brown;
		margin-top: 1rem;
		margin-bottom: 1rem;
		margin-left: auto;
		
	}
	.logo-name-1{
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		font-weight: 600 !important;
		color: brown;
		margin-top: 2rem;
		margin-bottom: 1rem;
		margin-left: -2rem;
	}
	.logo-name-2{
		font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
		font-size: 1.5rem;
		font-weight: 600 !important;
		color: red;
		text-align: center;
		padding: 2rem;
		
	}
	.content {
		background-color: white;
		width: 90%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		border-radius: 5rem 1.5rem 1.5rem 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.logo {
		float: right;
		width: 2rem;
		height: 2rem;
		max-width: 100%;
		max-height: 100%;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		margin-left: auto;
		margin-right: auto;
		
	}
	.logo1{
		height: 2rem;
		width: 4.5rem;
		max-width: 100%;
		max-height: 100%;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		margin-left: -2rem;
		margin-right: auto;
		
	}
	.span-area {
		display: flex;
		justify-content: center;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	.title {
		font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
		font-size: 1.5rem;
		font-weight: 600;
		color: black;
		text-align: center;
	}
	.footer{
		background-color: white;
		border-radius: 0.5rem;
		width: 90%;
		height: auto;
		margin-left: auto;
		margin-right: auto;
		margin-top: 1rem;
		margin-bottom: 1rem;
		text-align: center;
	}
	.footer span{
		color: brown;
		font-weight: 600 !important;
	}
</style>
